import store from './store/index';
import observeIntersection from './inc/observeIntersection';

/* eslint-disable-next-line */
const Vue = require('vue');

const {
    ref, onMounted, computed, watch,
} = Vue;

// Header Funktions
const headerFunctions = {
    name: 'HeaderFunctions',
    setup() {
        const ready = ref(false);
        const menuState = computed(() => store.getters.getMenuState);
        const magazineCat = computed(() => store.getters.getMagazineCat);
        const filteredPosts = computed(() => store.getters.getFilteredPosts);
        const postCount = computed(() => store.getters.getPostCount);
        const prevPost = computed(() => store.getters.getPrevPost);
        const nextPost = computed(() => store.getters.getNextPost);
        const startPage = computed(() => store.getters.getStartPage);
        const currentIdInMagazine = computed(() => store.getters.getCurrentIdInMagazine);
        /* eslint-disable-next-line */
        const { isKiosk } = wpUrls;

        onMounted(async() => {
            store.dispatch('setMagazineCat');
            await store.dispatch('setPosts');
            await store.dispatch('setPages');
            store.dispatch('setPagination');
            store.dispatch('getCurrentIdInMagazine');

            if (parseInt(isKiosk, 10) !== 1) {
                ready.value = true;
            }
        });

        const setMenuState = () => {
            store.dispatch('setMenuState', true);
        };

        return {
            ready,
            setMenuState,
            menuState,
            filteredPosts,
            magazineCat,
            postCount,
            prevPost,
            nextPost,
            startPage,
            currentIdInMagazine,
        };
    }
};
Vue.createApp(headerFunctions).use(store).mount('#headerNav');

// Menu
const magazineMenu = {
    name: 'MagazineMenu',
    setup() {
        const menuState = computed(() => store.getters.getMenuState);
        const filteredPosts = computed(() => store.getters.getFilteredPosts);
        const startPage = computed(() => store.getters.getStartPage);
        const currentIdInMagazine = computed(() => store.getters.getCurrentIdInMagazine);
        const magazineCat = computed(() => store.getters.getMagazineCat);
        const ready = ref(false);
        /* eslint-disable-next-line */
        const { isKiosk, magazinColors } = wpUrls;

        onMounted(() => {
            if (parseInt(isKiosk, 10) !== 1) {
                ready.value = true;
            }

            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape' && menuState.value) {
                    closeMenu();
                }
            });
        });

        const randomColor = () => (
            (magazinColors) ? magazinColors[Math.floor(Math.random() * magazinColors.length)] : false
        );

        const closeMenu = () => {
            store.dispatch('setMenuState', false);
        };

        return {
            filteredPosts,
            ready,
            menuState,
            closeMenu,
            startPage,
            currentIdInMagazine,
            magazineCat,
            randomColor,
        };
    }
};
Vue.createApp(magazineMenu).use(store).mount('#magazinList');

// Footer Pagination
const footerPagination = {
    name: 'FooterPagination',
    setup() {
        const ready = ref(false);
        const prevPost = computed(() => store.getters.getPrevPost);
        const nextPost = computed(() => store.getters.getNextPost);
        const currentIdInMagazine = computed(() => store.getters.getCurrentIdInMagazine);
        const prevHover = ref(false);
        const nextHover = ref(false);
        /* eslint-disable-next-line */
        const { isKiosk, footerPaginationColored, footerPaginationColor, footerPaginationArrowColor, footerPaginationHoverColor } = wpUrls;
        const footerPaginationColorVar = footerPaginationColored && footerPaginationColor
            ? `:root {
                --footerPagination__bgColor: ${footerPaginationColor};
                --footerPagination__arrowColor: ${footerPaginationArrowColor};
                --footerPagination__hoverColor: ${footerPaginationHoverColor};
            }`
            : '';

        onMounted(() => {
            if (parseInt(isKiosk, 10) !== 1) {
                ready.value = true;
            }

            if (footerPaginationColorVar) {
                const style = document.createElement('style');
                const head = document.head || document.getElementsByTagName('head')[0];

                head.appendChild(style);
                style.appendChild(document.createTextNode(footerPaginationColorVar));
            }
        });

        return {
            ready,
            prevPost,
            nextPost,
            currentIdInMagazine,
            footerPaginationColorVar,
            prevHover,
            nextHover,
        };
    }
};
Vue.createApp(footerPagination).use(store).mount('#footerPagination');

// Fixed Pagination
const fixedPagination = {
    name: 'FixedPagination',
    setup() {
        const ready = ref(false);
        const prevPost = computed(() => store.getters.getPrevPost);
        const nextPost = computed(() => store.getters.getNextPost);
        const currentIdInMagazine = computed(() => store.getters.getCurrentIdInMagazine);
        const isDark = ref(false);
        const contrast = ref(false);
        const isCustom = ref(false);
        const prevColor = ref(false);
        const nextColor = ref(false);
        /* eslint-disable-next-line */
        const { isKiosk, magazinColors } = wpUrls;

        onMounted(() => {
            if (!document.querySelectorAll('#bigHeaderWrapper.hasMedia').length) {
                isDark.value = true;
            }

            if (magazinColors) {
                prevColor.value = randomColor().color;
                nextColor.value = randomColor().color;
            }

            observeIntersection('#bigHeaderWrapper.hasMedia', (e, intersected) => {
                if (intersected) {
                    isDark.value = false;
                } else {
                    isDark.value = true;
                }
            }, false, 0, undefined, '-90% 0% -10% 0%');

            /* Full WIdth Img Observer ++++++++++++++++++++++++++++++++++++++++++*/
            observeIntersection(
                '.wp-block-image.alignfull, .wp-block-getwid-image-hotspot.alignfull, .parallaxBackground',
                (e, intersected) => {
                    if (intersected) {
                        isDark.value = false;
                    } else {
                        isDark.value = true;
                    }
                },
                false,
                0,
                undefined,
                '-90% 0% -10% 0%'
            );

            observeIntersection(
                '.contentTeaser',
                (e, intersected) => {
                    if (!intersected) {
                        contrast.value = false;
                    } else {
                        contrast.value = true;
                    }
                },
                false,
                0,
                undefined,
                '-90% 0% -10% 0%'
            );

            observeIntersection('#footerPagination, .footer, #newsletter', (e, intersected) => {
                if (intersected) {
                    ready.value = false;
                } else {
                    ready.value = true;
                }
            }, false, 0, undefined, '0px 0px -64px 0px');

            if (parseInt(isKiosk, 10) !== 1) {
                ready.value = true;
            }
        });

        observeIntersection(
            '.fixedNavStyleTrigger',
            (e, intersected) => {
                if (intersected) {
                    isCustom.value = true;
                    isDark.value = false;
                } else {
                    isCustom.value = false;
                    isDark.value = true;
                }
            },
            false,
            0,
            undefined,
            '-90% 0% -10% 0%'
        );

        const randomColor = () => (
            (magazinColors) ? magazinColors[Math.floor(Math.random() * magazinColors.length)] : false
        );

        return {
            ready,
            isDark,
            contrast,
            isCustom,
            prevPost,
            nextPost,
            currentIdInMagazine,
            randomColor,
            nextColor,
            prevColor,
        };
    }
};
Vue.createApp(fixedPagination).use(store).mount('#fixedPagination');

// Posts Index
if (document.getElementById('postsIndex')) {
    const postsIndex = {
        name: 'PostsIndex',
        setup() {
            const ready = ref(false);
            const filteredPosts = computed(() => store.getters.getFilteredPosts);
            const startPage = computed(() => store.getters.getStartPage);
            const indexPosts = ref([]);
            /* eslint-disable-next-line */
            const { isKiosk, magazinColors } = wpUrls;

            const randomColor = () => (
                (magazinColors) ? magazinColors[Math.floor(Math.random() * magazinColors.length)] : false
            );

            watch(filteredPosts, () => {
                if (parseInt(isKiosk, 10) !== 1) {
                    ready.value = true;
                }

                if (
                    window.excludePosts !== null
                        && typeof window.excludePosts !== 'undefined'
                        && window.excludePosts !== ''
                ) {
                    indexPosts.value = filteredPosts.value.filter((post) => !window.excludePosts.includes(post.id));
                } else {
                    indexPosts.value = filteredPosts.value;
                }
            });

            return {
                startPage,
                ready,
                indexPosts,
                randomColor,
            };
        }
    };

    Vue.createApp(postsIndex).use(store).mount('#postsIndex');
}

// Header Logo
const headerLogo = {
    name: 'HeaderLogo',
    setup() {
        const ready = ref(false);
        const startPage = computed(() => store.getters.getStartPage);
        const startPageUrl = ref('void:javascript;');
        /* eslint-disable-next-line */
        const { isKiosk, logoLink } = wpUrls;

        watch(startPage, () => {
            if (parseInt(isKiosk, 10) !== 1) {
                ready.value = true;
            }

            if (logoLink) { return; }
            startPageUrl.value = startPage.value.permalink;
        });

        onMounted(() => {
            if (logoLink) { startPageUrl.value = logoLink; }
        });

        return {
            startPage,
            ready,
            startPageUrl,
        };
    }
};
Vue.createApp(headerLogo).use(store).mount('#headerLogo');

// Kiosk Search
if (document.getElementById('kioskSearch')) {
    const kioskSearch = {
        name: 'KioskSearch',
        setup() {
            const ready = ref(false);
            const pages = computed(() => store.getters.getPages);
            const posts = computed(() => store.getters.getPosts);
            const magazinSearch = ref('');
            const filteredPages = computed(() => {
                const searchTerm = magazinSearch.value.toLowerCase();
                if (searchTerm?.length > 2) {
                    /* eslint-disable-next-line */
                    const searchInPosts = posts.value.filter((post) => {
                        const postsSearchContent = `${post.title?.rendered.toLowerCase()}
                        ${post.content?.rendered.toLowerCase()}`;

                        if (postsSearchContent.includes(searchTerm)) {
                            return true;
                        }

                        return false;
                    });

                    return pages.value.filter((page) => {
                        let pageInSearch = false;
                        const pagesSearchContent = `${page.title?.rendered.toLowerCase()}
     ${page.content?.rendered.toLowerCase()}`;

                        if ((pagesSearchContent.includes(searchTerm)
                            && page.acf.magazineStart === true)
                            || (searchInPosts.find((post) => post.magazinecat.includes(page.acf.magazineCat)
                            && page.acf.magazineStart === true))
                        ) {
                            pageInSearch = true;
                        }

                        return pageInSearch;
                    });
                }
                return false;
            });

            onMounted(async() => {
                await store.dispatch('setPosts');
                await store.dispatch('setPages');

                ready.value = true;
            });

            const setUrlfromFirstResult = () => {
                if (filteredPages.value.length > 0) {
                    const firstResult = filteredPages.value[0];

                    window.location.href = firstResult.permalink;
                }
            };

            return {
                pages,
                ready,
                magazinSearch,
                filteredPages,
                setUrlfromFirstResult,
            };
        }
    };
    Vue.createApp(kioskSearch).use(store).mount('#kioskSearch');
}
