// store/index.js
/* eslint-disable-next-line */
import { createStore } from 'vuex';
/* eslint-disable-next-line */
import axios from 'axios';

/* eslint-disable-next-line */
const queryString = require('query-string');

const store = createStore({
    state: {
        posts: [],
        filteredPosts: [],
        pages: [],
        startPage: undefined,
        menuState: false,
        magazineCat: null,
        postCount: null,
        prevPost: null,
        nextPost: null,
        currentIdInMagazine: false,
    },
    getters: {
        getPosts: (state) => state.posts,
        getFilteredPosts: (state) => state.filteredPosts,
        getPages: (state) => state.pages,
        getStartPage: (state) => state.startPage,
        getPostCount: (state) => state.postCount,
        getPrevPost: (state) => state.prevPost,
        getNextPost: (state) => state.nextPost,
        getMenuState: (state) => state.menuState,
        getMagazineCat: (state) => state.magazineCat,
        getCurrentIdInMagazine: (state) => state.currentIdInMagazine,
    },
    mutations: {
        SET_POSTS: (state, res) => {
            state.posts = res;
        },
        SET_FILTERED_POSTS: (state, res) => {
            state.filteredPosts = res;
        },
        SET_PAGES: (state, res) => {
            state.pages = res;
        },
        SET_START_PAGE: (state, res) => {
            state.startPage = res;
        },
        SET_MENU_STATE: (state, res) => {
            state.menuState = res;
        },
        SET_MAGAZINE_CAT: (state, res) => {
            state.magazineCat = res;
        },
        SET_POST_COUNT: (state, res) => {
            state.postCount = res;
        },
        SET_PREV_POST: (state, res) => {
            state.prevPost = res;
        },
        SET_NEXT_POST: (state, res) => {
            state.nextPost = res;
        },
        SET_CURRENT_ID_IN_MAGAZINE: (state, res) => {
            state.currentIdInMagazine = res;
        },
    },
    actions: {
        getCurrentIdInMagazine({ state, commit }) {
            /* eslint-disable-next-line */
            const currentId = parseInt(wpUrls.currentID, 10);
            const currentIdInPosts = state.filteredPosts.find((post) => post.id === currentId);
            let idInMagazine = false;

            if (currentIdInPosts || (state.startPage && state.startPage.id === currentId)) {
                idInMagazine = true;
            }

            commit('SET_CURRENT_ID_IN_MAGAZINE', idInMagazine);
        },
        setMenuState({ commit }, status) {
            if (status) {
                document.body.classList.add('open');
            } else {
                document.body.classList.remove('open');
            }

            commit('SET_MENU_STATE', status);
        },
        setMagazineCat({ state, commit }) {
            if (state.magazineCat !== null) return;
            let currentMagazineCat = null;
            /* eslint-disable-next-line */
            const magazineCat = parseInt(wpUrls.magazineCat, 10);
            const storagemagazineCat = parseInt(localStorage.getItem('magazineCat'), 10);
            /* eslint-disable-next-line */
            const magazineCatFallback = parseInt(wpUrls.magazineCatFallback, 10);
            const magazineCatParam = parseInt(queryString.parse(window.location.search).mc, 10);

            if (!Number.isNaN(magazineCat)) {
                currentMagazineCat = magazineCat;
                /* eslint-disable-next-line */
                console.log('MC from Startpage');
                console.log(magazineCat);
            } else if (!Number.isNaN(magazineCatParam)) {
                console.log(magazineCatParam);
                currentMagazineCat = magazineCatParam;
                /* eslint-disable-next-line */
                console.log('MC from Param');
            } else if (!Number.isNaN(storagemagazineCat)) {
                currentMagazineCat = storagemagazineCat;
                /* eslint-disable-next-line */
                console.log('MC from Store');
            } else if (!Number.isNaN(magazineCatFallback)) {
                /* eslint-disable-next-line */
                console.log('MC from Fallback');
                currentMagazineCat = magazineCatFallback;
            }

            if (currentMagazineCat !== null && !Number.isNaN(currentMagazineCat)) {
                window.history.replaceState(
                    null,
                    null,
                    queryString.stringifyUrl({ url: window.location.href, query: { mc: currentMagazineCat } })
                );
                localStorage.setItem('magazineCat', currentMagazineCat);
                const magazinSearchCat = document.querySelectorAll('#magazinSearch [name=magazinecat]');
                const shareLink = document.querySelectorAll('.shareLink');

                if (magazinSearchCat && magazinSearchCat.length > 0) {
                    magazinSearchCat[0].value = currentMagazineCat;
                }

                // Search Input on Results Page
                if (document.querySelectorAll('body.search-results').length) {
                    const mainSearchMagazinSearchCat = document.querySelectorAll('#mainSearch [name=magazinecat]');

                    if (mainSearchMagazinSearchCat && mainSearchMagazinSearchCat.length > 0) {
                        mainSearchMagazinSearchCat[0].value = currentMagazineCat;
                    }
                }

                if (shareLink.length) {
                    for (let shareLinkIndex = 0; shareLinkIndex < shareLink.length; shareLinkIndex++) {
                        const link = shareLink[shareLinkIndex];
                        link.setAttribute('href', `${link.getAttribute('href')}?mc=${currentMagazineCat}`);
                    }
                }
            }

            commit('SET_MAGAZINE_CAT', currentMagazineCat);
        },
        setPagination({ state, commit }) {
            /* eslint-disable-next-line */
            const currentId = wpUrls.currentID;
            const indexCurrent = state.filteredPosts.findIndex((post) => post.id === parseInt(currentId, 10));

            let filteredPostsCount = state.filteredPosts.length + 1;
            if (filteredPostsCount < 10) {
                filteredPostsCount = `0${state.filteredPosts.length + 1}`;
            }

            if (indexCurrent >= 0) {
                if (indexCurrent + 1 !== state.filteredPosts.length) {
                    commit('SET_NEXT_POST', state.filteredPosts[indexCurrent + 1]);
                }

                if (indexCurrent === 0) {
                    commit('SET_PREV_POST', state.startPage);
                } else {
                    commit('SET_PREV_POST', state.filteredPosts[indexCurrent - 1]);
                }

                commit('SET_POST_COUNT', `${indexCurrent + 2}/${filteredPostsCount}`);
                if (indexCurrent + 2 < 10) {
                    commit('SET_POST_COUNT', `0${indexCurrent + 2}/${filteredPostsCount}`);
                }
            } else {
                [state.nextPost] = state.filteredPosts;
                commit('SET_POST_COUNT', `01/${filteredPostsCount}`);
            }
        },
        async setPages({ state, commit }) {
            if (state.pages.length) return;
            try {
                const pages = await axios.get(`${window.wpUrls.baseUrl}/wp-json/wp/v2/pages`, {
                    params: {
                        _embed: true,
                        _fields: 'title, id, thumb, permalink, acf, content',
                        per_page: 9999,
                    }
                });
                commit('SET_PAGES', pages.data);
                if (state.magazineCat !== null) {
                    commit('SET_START_PAGE', pages.data.find((page) => page.acf.magazineCat === state.magazineCat));
                }
            } catch (err) {
                /* eslint-disable-next-line */
                console.error('setPages', err)
            }
        },
        async setPosts({ state, commit }) {
            if (state.posts.length) return;
            try {
                const posts = await axios.get(`${window.wpUrls.baseUrl}/wp-json/wp/v2/magazin`, {
                    params: {
                        _embed: true,
                        /* eslint-disable-next-line */
                        _fields: 'title, id, thumb, permalink, acf, magazinecat, primarymagazinecat, filledexcerpt, content',
                        per_page: 9999,
                    }
                });
                commit('SET_POSTS', posts.data);

                commit('SET_FILTERED_POSTS', posts.data.filter((post) => post.magazinecat
                    .some((cat) => cat === state.magazineCat)).sort((a, z) => {
                    const aPage = a.acf.magazinPages.find((post) => post.cat === state.magazineCat);
                    const zPage = z.acf.magazinPages.find((post) => post.cat === state.magazineCat);

                    if (aPage && zPage) {
                        return aPage.page - zPage.page;
                    }
                    return false;
                }));
            } catch (err) {
                /* eslint-disable-next-line */
                console.error('getPosts', err)
            }
        },
    },
});

export default store;
